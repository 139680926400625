.app-container {
  overflow:hidden;
  height: 100%;
  width: 100%;
}

.error-toast-black {
  background: #000;
}

.error-toast-body {
}

.error-toast-progress {
  background: #fc405b;
}

html {
  height: 100%;
}

#root {
  width: 100%;
  display: flex;
  justify-content: center;
}

.App {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
